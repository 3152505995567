import React, { useEffect } from "react"
import { graphql, Link, navigate } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layouts"
import linkResolver from "./../utils/linkResolver"
import { buildBreadcrumb } from "./../utils/buildBreadcrumb"
import Breadcrumb from "./../components/slices/Breadcrumb"
import { EditPod } from "./../components/pods"
import { Hero } from "./../components/hero"

// Query for the Blog Post content in Prismic
export const query = graphql`
  query CategoryQuery($uid: String) {
    allPrismicHomepage {
      edges {
        node {
          data {
            title {
              text
            }
            seotitle
            seodescription
          }
          id
          url
          type
        }
      }
    }
    allPrismicLatestEditsListing {
      edges {
        node {
          data {
            title {
              text
            }
            hero_image {
              url
            }
          }
          id
          uid
          url
          type
        }
      }
    }
    allPrismicCategory {
      edges {
        node {
          data {
            title {
              text
            }
            hero_text_colour
            hero_image {
              url(imgixParams: { q: 90 })
              thumbnails {
                huge {
                  url(imgixParams: { q: 90 })
                }
                medium {
                  url(imgixParams: { q: 90 })
                }
                mega {
                  url(imgixParams: { q: 90 })
                }
                small {
                  url(imgixParams: { q: 90 })
                }
                tiny {
                  url(imgixParams: { q: 90 })
                }
                large {
                  url(imgixParams: { q: 90 })
                }
              }
            }
            name
            seotitle
            seodescription
          }
          id
          uid
          url
          type
        }
      }
    }
    allPrismicEdit(
      sort: { fields: data___published_date, order: DESC }
      filter: { data: { category: { uid: { eq: $uid } } } }
    ) {
      edges {
        node {
          data {
            hero_text_colour
            published_date
            seotitle
            seodescription
            title {
              text
            }
            hero_image {
              url
            }
            category {
              __typename
              document {
                ... on PrismicCategory {
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          uid
          type
        }
      }
    }
  }
`

export default ({ data, location }) => {
  // Define the Post content returned from Prismic
  const {
    allPrismicEdit,
    allPrismicCategory,
    allPrismicHomepage,
    allPrismicLatestEditsListing,
  } = data

  useEffect(() => {
    if (location.state?.offset) {
      window.scroll({
        top: location.state.offset,
        left: 0,
      })
    }
  })

  let thisCategory = {}
  const homeData = allPrismicHomepage.edges[0].node
  const latestEditsData = allPrismicLatestEditsListing.edges[0].node
  let allCategories = []

  for (var i = 0; i < allPrismicCategory.edges.length; i++) {
    let hasMatch = false
    const currentLoopCategory = allPrismicCategory.edges[i].node

    if (location.pathname.includes(currentLoopCategory.uid)) {
      thisCategory = currentLoopCategory
    }

    for (var j = 0; j < allCategories.length; j++) {
      if (allCategories[j].data.name === currentLoopCategory.data.name) {
        hasMatch = true
      }
    }

    if (!hasMatch) {
      allCategories.push(currentLoopCategory)
    }
  }

  const thisBreadcrumb = buildBreadcrumb([
    homeData,
    latestEditsData,
    thisCategory,
  ])

  const categoryClick = (url, e) => {
    e.preventDefault()
    const state = { offset: window.pageYOffset }
    navigate(url, { state })
  }

  return (
    <Layout>
      <Helmet>
        <title>
          {thisCategory.seotitle
            ? thisCategory.seotitle
            : homeData.data.seotitle}
        </title>
        <meta
          name="description"
          content={
            thisCategory.seodescription
              ? thisCategory.seodescription
              : homeData.seodescription
          }
        />
      </Helmet>

      <Hero props={thisCategory.data} />
      <div className="[ container -pinched ]">
        <Breadcrumb className="border-bottom" data={thisBreadcrumb} />
        <div className="category-menu">
          <div className="category-menu__inner">
            <Link
              className="category-menu__item button"
              to={linkResolver(latestEditsData)}
              onClick={(e) => categoryClick(linkResolver(latestEditsData), e)}
            >
              All categories
            </Link>
            {allCategories.map((category, index) => (
              <Link
                key={index}
                className="category-menu__item button"
                activeClassName="active"
                to={linkResolver(category)}
                onClick={(e) => categoryClick(linkResolver(category), e)}
              >
                {category.data.name}
              </Link>
            ))}
          </div>
        </div>
        <div className="latest-edits">
          <div className="latest-edits__grid">
            {allPrismicEdit &&
              allPrismicEdit.edges.map((edit, index) => {
                let editItem = edit.node
                return <EditPod data={editItem} key={index} />
              })}
          </div>
          {!allPrismicEdit.edges.length && (
            <h2 className="title -center">No edits found</h2>
          )}
        </div>
      </div>
    </Layout>
  )
}
